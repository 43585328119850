export const tokenList = {
  "name": "PancakeSwap Default List",
  "timestamp": "2021-03-17T09:56:23Z",
  "version": {
    "major": 2,
    "minor": 1,
    "patch": 0
  },
  "tags": {},
  "logoURI": "https://exchange.pancakeswap.finance/images/pancakeswap.png",
  "keywords": ["pancake", "default"],
  "tokens": [
    {
      "name": "Pawthereum",
      "symbol": "PAWTH",
      "address": "0xdf09234aB3247656F6672DCcc011F78bBabCB8Ea",
      "chainId": 97,
      "decimals": 9,
      "logoURI": "https://pawthereum.com/wp-content/uploads/shared-files/pawth-logo-transparent.png",
      "typicalBuyTax": 8,
      "typicalSellTax": 8,
      "color": "#ff65b3",
      "description": "Pawthereum is a decentralized, charity cryptocurrency that gives back to animal shelters and advocates for the well-being of animals in need!",
    },
    {
      "name": "Pawthereum",
      "symbol": "PAWTH",
      "address": "0x0165878A594ca255338adfa4d48449f69242Eb8F",
      "chainId": 1337,
      "decimals": 9,
      "logoURI": "https://pawthereum.com/wp-content/uploads/shared-files/pawth-logo-transparent.png",
      "typicalBuyTax": 8,
      "typicalSellTax": 8,
      "color": "#ff65b3",
      "description": "Pawthereum is a decentralized, charity cryptocurrency that gives back to animal shelters and advocates for the well-being of animals in need!",
    },
    {
      "name": "BNB",
      "symbol": "BNB",
      "address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      "chainId": 1337,
      "decimals": 18,
      "logoURI": "https://pancake.kiemtienonline360.com/images/coins/0xae13d989dac2f0debff460ac112a837c89baa7cd.png",
      "typicalBuyTax": 0,
      "typicalSellTax": 0,
      "color": "#F0BA03",
      "isNative": true
    },
    {
      "name": "BNB",
      "symbol": "BNB",
      "address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      "chainId": 97,
      "decimals": 18,
      "logoURI": "https://pancake.kiemtienonline360.com/images/coins/0xae13d989dac2f0debff460ac112a837c89baa7cd.png",
      "typicalBuyTax": 0,
      "typicalSellTax": 0,
      "color": "#F0BA03",
      "isNative": true
    },
    //{
    //   "name": "Wrapped BNB",
    //   "symbol": "WBNB",
    //   "address": "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    //   "chainId": 97,
    //   "decimals": 18,
    //   "logoURI": "https://cdn.icon-icons.com/icons2/3006/PNG/512/bnb_cryptocurrencies_icon_188408.png",
    //   "typicalBuyTax": 0,
    //   "typicalSellTax": 0,
    //   "color": "#F4BB0B",
    // },
    // {
    //   "name": "Kodi Coin",
    //   "symbol": "KODI",
    //   "address": "0x71a3406E8A04ec52b7f7aCcE94C93f7a48FBF2C9",
    //   "chainId": 97,
    //   "decimals": 18,
    //   "logoURI": "https://bafybeievtapjdh32pqp3kljnhhymzf6fc2kxkqsajsujac34j5wto3aisy.ipfs.infura-ipfs.io",
    //   "dex": "pancakeswap",
    //   "typicalBuyTax": 11,
    //   "typicalSellTax": 12,
    //   "color": "#8751D8"
    //   "description": "KODI is more than just a new project, it will provide immediate utility through the implementation of our marketing agency. Starting with our 24/7 Radio show, the Kodi team will be bringing you everything from crypto podcasts, music, and much more.",
    // },
    {
      "name": "SafeMoon",
      "symbol": "SFM",
      "address": "0x8a791620dd6260079bf849dc5567adc3f2fdc318",
      "chainId": 1337,
      "decimals": 9,
      "logoURI": "https://safemoon.com/img/logo.svg",
      "dex": "pancakeswap",
      "typicalBuyTax": 10,
      "typicalSellTax": 10,
      "color": "#008A81",
      "description": "A human-focused technology and innovation business expanding blockchain technologies for a brighter tomorrow.",
    },
    {
      "name": "SafeMoon",
      "symbol": "SFM",
      "address": "0x44255F8De687371B5f7F8c9398A300e5a7628895",
      "chainId": 97,
      "decimals": 9,
      "logoURI": "https://safemoon.com/img/logo.svg",
      "dex": "pancakeswap",
      "typicalBuyTax": 10,
      "typicalSellTax": 10,
      "color": "#008A81",
      "description": "A human-focused technology and innovation business expanding blockchain technologies for a brighter tomorrow.",
    }
  ]
}